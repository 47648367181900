import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px' }}>

      <small>
        <h1 class="py-3 text-center ">Privacy Policy of Trésor Connect</h1>
        <div class="text-justify">
          <p>We believe that you should always know what data we collect from you and how we use it, and that you should have effective control over both. We want to give you the opportunity to make the best decisions about the information you share with us. This is the purpose of
            this Privacy Policy.
          </p>
          <ol>
            <li>
              <span class="confidentiality-title">Information Gathering</span>
              <p>
                We collect information when you register on our site, when you log in to your account and/or when you log out. The information collected includes your name, e-mail address and/or telephone number.

                In addition, we automatically receive and store information from your computer and browser, including your IP address, software and hardware, and the page you request.

              </p>
            </li>
            <li>
              <span class="confidentiality-title">Use of information</span>
              <p>
                Any information we collect from you may be used to :
                <ul>
                  <li>Customize your experience and meet your individual needs</li>
                  <li>Provide personalized advertising content</li>
                  <li>Improving our site</li>
                  <li>Improve customer service and your support needs</li>
                  <li>Contact you by e-mail</li>
                  <li>Administer a contest, promotion, or survey</li>
                </ul>
              </p>
            </li>
            <li>
              <span class="confidentiality-title">Privacy</span>
              <p>
                We are the sole owners of the information collected on this site. Your personal information will not be sold, exchanged, transferred, or given to another company for any reason, without your consent, outside of what is necessary to meet a request.
              </p>
            </li>
            <li>
              <span class="confidentiality-title">Disclosure to third parties </span>
              <p>
                We do not sell, exchange or transfer your personally identifiable information to third parties. This does not include trusted third parties who help us operate our website or conduct our business, as long as these parties agree to keep this information confidential.

              </p>
              <p>
                We believe it is necessary to share information in order to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical security of any person, violations of our Terms of Use, or when required by law.
              </p>
              <p>Non-private information, however, may be provided to other parties for marketing, advertising, or other uses.</p>
            </li>
            <li>
              <span class="confidentiality-title">Protection of Information</span>
              <p>
                We implement a variety of security measures to protect the security of your personal information. We use state-of-the-art encryption to protect sensitive information transmitted online. We also protect your information offline. Only employees who need to perform specific work (for example, customer service) have access to personally identifiable information. Computers and servers used to store personally identifiable information are stored in a secure environment.
              </p>
            </li>
            <li>
              <span class="confidentiality-title">Use of cookies</span>
              <p>
                Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. En outre, nos cookies améliorent l'expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.
              </p>
            </li>
            <li>
              <span class="confidentiality-title">Unsubscribe</span>
              <p>
                We use the email address you provide to send you information and updates about your order, company news on an occasional basis, information about related products, etc. If at any time you wish to unsubscribe and no longer receive emails, detailed unsubscribe instructions are included at the bottom of each email.
              </p>
            </li>
            <li>
              <span class="confidentiality-title">Children and our services </span>
              <p>
                Our services are not intended for children and you are prohibited from using our services if you are under 13 years old. You must also be old enough to consent to the processing of your personal data in your country (in some countries we may allow your parent or guardian to do so on your behalf).
              </p>
            </li>
            <li>
              <span class="confidentiality-title">Right of access to personal data - Complaints</span>
              <p>
                The information requested and collected about you is necessary and obligatory to create your user account and to facilitate the identification and recognition of the User. In accordance with Law No. 2013-450 of June 19, 2013 on the protection of personal data, the User has the right to access, rectify, modify and delete data concerning him. However, he may not, in any way, modify the e-mail address he has provided. To exercise the above rights, the User will use the relevant tabs on the application. In general, all complaints must be made in writing and sent to the following email address: support@myopinion.ci. The communication of User data and the validation of these Terms of Services authorize the Company to use said data until the User decides otherwise.
              </p>
            </li>
            <li>
              <span class="confidentiality-title">Consent</span>
              <p>
                By using our site, you consent to our privacy policy.
              </p>
            </li>
            <li>
              <span class="confidentiality-title">Amendment of this Privacy Policy</span>
              <p>
                We reserve the right to change this Privacy Policy at any time. The latest version of this policy governs our processing of your personal data and will always be available on the dedicated tab. If we change this policy in a manner that we consider, in our sole discretion, to be substantial, we will notify you by sending an email to the address associated with your account. By continuing to access or use the Services after the effective date of these changes, you agree to be bound by the terms set out in the new Privacy Policy.
              </p>
            </li>
          </ol>
        </div>

      </small>


    </div>
  )
}

export default PrivacyPolicy