import React from 'react'
import styles from './Messaging.module.css'
import { useTranslation } from 'react-i18next';
import OneToOne from './OneToOne/OneToOneConversation/OneToOne';
import InMail from './InMail/InMail';
import Topbar from 'components/Topbar/Topbar';
import NavigationBottom from 'components/NavigationBottom/NavigationBottom';
import { useEffect } from 'react';
import localStorage from 'redux-persist/es/storage';


const Messaging = () => {


    const { t, i18n } = useTranslation()
    const [tabIndex, setTabIndex] = React.useState(0);



    const handleTabChange = async (index) => {
        setTabIndex(index);
    };

    return (
        <div className={styles.page}>
            <Topbar />
            <div>

                {/* TAB */}
                <div className={styles.tab}>
                    <span onClick={() => handleTabChange(0)}
                        className={tabIndex === 0
                            ? styles.tabLabelActive
                            : styles.tabLabel
                        }>
                        Inbox
                    </span>
                    <span onClick={() => handleTabChange(1)}
                        className={tabIndex === 1
                            ? styles.tabLabelActive
                            : styles.tabLabel
                        }>
                        InMail
                    </span>
                </div>

                {/* TAB PANEL */}
                <div className={tabIndex === 0
                    ? styles.tabViewActive
                    : styles.tabView
                }>
                    <OneToOne />
                </div>
                <div className={tabIndex === 1
                    ? styles.tabViewActive
                    : styles.tabView
                }>
                    <InMail />
                </div>

            </div>
            <NavigationBottom />
        </div>
    )
}

export default Messaging