import React from 'react'

const PolitiqueConfidentialite = () => {
  return (
    <div style={{marginLeft: '50px', marginRight: '50px', marginBottom: '50px'}}>
      <div>

        <h1 style={{ paddingTop: '50px', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left', textAlign: 'center' }}>
          Politique de Confidentialité du Reseau Social privé « Trésor Connect »
        </h1>

        <div className="text-justify">
          <p>Nous estimons que vous devriez toujours savoir quelles données nous recueillons de votre part et comment nous les utilisons, et que vous devriez disposer d’un contrôle effectif sur ces deux aspects. Nous souhaitons vous donner la possibilité de prendre les meilleures décisions qui soient quant aux informations que vous partagez avec nous. C’est là l’objet de la présente Politique de confidentialité.</p>
          <ol>
            <li>
              <span className="confidentiality-title">Collecte d’information</span><br />
              <p>
                Nous recueillons des informations lorsque vous vous inscrivez sur notre site, lorsque vous vous connectez à votre compte et / ou lorsque vous vous déconnectez. Les informations recueillies incluent votre nom, votre adresse e-mail et/ou numéro de téléphone. 
                En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels et votre matériel, et la page que vous demandez.
              </p>
            </li>
            <li>
              <span className="confidentiality-title">Utilisation d’informations</span><br />
              <p>
                Toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour :
              </p><ul>
                <li>Personnaliser votre expérience et répondre à vos besoins individuels</li>
                <li>Fournir un contenu publicitaire personnalisé</li>
                <li>Améliorer notre site</li>
                <li>Améliorer le service client et vos besoins de prise en charge</li>
                <li>Vous contacter par e-mail</li>
                <li>Administrer un concours, une promotion, ou une enquête</li>
              </ul>
              <p />
            </li>
            <li>
              <span className="confidentiality-title">Confidentialité</span><br />
              <p>
                Nous sommes les seuls propriétaires des informations recueillies sur ce site. Vos informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre société pour n’importe quelle raison, sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une demande. 
              </p>
            </li>
            <li>
              <span className="confidentiality-title">Divulgation à des tiers</span><br />
              <p>
                Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierces parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.
              </p>
              <p>
                Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.
              </p>
              <p>Les informations non-privées, cependant, peuvent être fournies à d’autres parties pour le marketing, la publicité, ou d’autres utilisations.</p>
            </li>
            <li>
              <span className="confidentiality-title">Protection des informations</span><br />
              <p>
                Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage à la pointe de la technologie pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.                    
              </p>
            </li>
            <li>
              <span className="confidentiality-title">Utilisation des cookies</span><br />
              <p>
                Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. En outre, nos cookies améliorent l'expérience utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.
              </p>
            </li>
            <li>
              <span className="confidentiality-title">Désabonnement</span><br />
              <p>
                Nous utilisons l’adresse e-mail que vous fournissez pour vous envoyer des informations et mises à jour relatives à votre commande, des nouvelles de l’entreprise de façon occasionnelle, des informations sur des produits liés, etc. Si à n’importe quel moment vous souhaitez vous désinscrire et ne plus recevoir d’e-mails, des instructions de désabonnement détaillées sont incluses en bas de chaque e-mail.
              </p>
            </li>
            <li>
              <span className="confidentiality-title">Les enfants et nos services</span><br />
              <p>
                Nos services ne sont pas destinés aux enfants et il vous est interdit d’utiliser nos services si vous avez moins de 13 ans. Vous devez également être suffisamment âgé pour consentir au traitement de vos données à caractère personnel dans votre pays (dans certains pays, il se peut que nous permettions à votre parent ou votre tuteur de le faire en votre nom).
              </p>
            </li>
            <li>
              <span className="confidentiality-title">Droit d’accès aux données personnelles - Réclamations</span><br />
              <p>
                Les informations demandées et recueillies à votre sujet sont nécessaires et obligatoires pour créer votre compte utilisateur et pour faciliter l’identification et la reconnaissance de l’Utilisateur. Conformément à la loi n° 2013-450 du 19 juin 2013 sur la protection des données à caractère personnel, l’Utilisateur a le droit d’accéder, de rectifier, de modifier et de supprimer les données le concernant. Toutefois, il ne peut en aucun cas modifier l’adresse e-mail qu’il a fournie. Pour exercer les droits ci-dessus, l’utilisateur utilisera les onglets pertinents de l’application. En général, toutes les plaintes doivent être déposées par écrit et envoyées à l’adresse électronique suivante : support@myopinion.ci. La communication des données de l’Utilisateur et la validation des présentes Conditions d’utilisation autorisent la Société à utiliser lesdites données jusqu’à ce que l’Utilisateur en décide autrement.
              </p>
            </li>
            <li>
              <span className="confidentiality-title">Consentement</span><br />
              <p>
                En utilisant notre site, vous consentez à notre politique de confidentialité.
              </p>
            </li>
            <li>
              <span className="confidentiality-title">Modification de la présente politique de confidentialité</span><br />
              <p>
                Nous nous réservons le droit de modifier la présente Politique de confidentialité à tout moment. La dernière version de la présente politique régit notre traitement de vos données à caractère personnel et sera toujours disponible à l’onglet dédié. Si nous modifions la présente politique d’une manière que nous considérons, à notre seule discrétion, comme substantielle, nous vous en informerons en envoyant un email à l’adresse associée à votre compte. En continuant d’accéder aux services ou en les utilisant après l’entrée en vigueur de ces changements, vous acceptez d’être lié aux conditions énoncées dans la nouvelle Politique de confidentialité.
              </p>
            </li>
          </ol>
        </div>
      </div>
      
    </div>
  )
}

export default PolitiqueConfidentialite