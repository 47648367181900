import React, { memo } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap';
import './HomeOpinions.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { api } from '../../../api/api'
import Opinion from '../../Opinion/Opinion'
import { useAccessToken, useHomeReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import useReducerHomeAction from '../../../hooks/ReducerHooksAction/useReducerHomeAction'
import ViewOpinion from '../../viewOpinion/ViewOpinion'
import { BsArrowLeft, BsArrowReturnLeft } from 'react-icons/bs'
import PeopleMatchCarousel from '../../PeopleMatchCarousel/PeopleMatchCarousel'


const HomeOpinions = () => {

    // STATE
    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { ADD_HOME_OPINIONS, DELETE_HOME_OPINION, REFRESH_HOME_OPINIONS, MUTED_USER_HOME_OPINION, UPDATE_HOME_OPINION } = useReducerHomeAction()
    const { data: HomeData, store: HomeStore } = useHomeReducer()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [opinions, setOpinions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isRefresh, setIsRefresh] = useState(true)


    useEffect(() => {
        fetchOpinions()
    }, [])

    const fetchOpinions = async () => {
        if (isRefresh) {
            setIsLoading(true)
        }
        try {
            const request = await api(`api/opinions/v2/welcome?page=${page}`, 'GET', {}, accessToken)
            const response = await request.json()
            console.log('Response fetch opinions home:', response)
            if (request.status === 200) {
                if (response.success) {

                    if (response?.opinions?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }

                    if (response?.opinions?.data?.lengh === 0) {
                        setIsEmpty(true)
                        return
                    }

                    if (response?.opinions?.current_page == 1) {
                        REFRESH_HOME_OPINIONS(response?.opinions?.data)
                    } else {
                        ADD_HOME_OPINIONS(response?.opinions?.data)
                    }

                    setPage(item => item + 1)
                }
            }
            setIsRefresh(false)
            setIsLoading(false)
        } catch (error) {
            fetchOpinions()
            setIsLoading(false)
            console.warn(error.message);
        }
    };




    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '92vh', backgroundColor: 'white' }}>
                <div style={{ textAlign: 'center', marginTop: '40vh' }}>
                    <Spinner variant='secondary' />
                    <h6 style={{ color: '#808080', marginTop: '5%' }}>
                        {t('loading_audience')}
                    </h6>
                </div>
            </div>
        )
    }


    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        variant='secondary'
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }



    return (
        <div id="HomeScrollableDiv" className='home-opinions'>
            <InfiniteScroll
                dataLength={HomeData.opinions.length}
                next={fetchOpinions}
                hasMore={hasMore}
                loader={renderLoader()}
                scrollableTarget="HomeScrollableDiv"
                scrollThreshold={0.9}
                style={{
                    width: '99.5%',
                    overflowX: 'hidden',
                }}>
                <PeopleMatchCarousel />
                {HomeData.opinions.map((item, index) => {
                    return (
                        <Opinion
                            key={index}
                            item={item}
                            index={index}
                            opinion={item}
                            updateOpinion={UPDATE_HOME_OPINION}
                            deleteOpinion={DELETE_HOME_OPINION}
                            handleMuteUser={MUTED_USER_HOME_OPINION}
                            sourceFrom={'Home'}
                        />
                    )
                })}
            </InfiniteScroll>
        </div>
    )
}

export default memo(HomeOpinions)